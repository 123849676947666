
import {defineComponent, ref} from "vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import {ISearchField} from "@/core/entity/ISearchField";
import {OPERATOR, SEARCH_ENTITY} from "@/core/config/Constant";
import {ISearchFilter} from "@/core/entity/ISearchFilter";
import PolicyService from "@/core/services/PolicyService";
import {InsuredSelect, MoveClaimCaseSelect} from "@/core/composite/composite";
import ClaimService from "@/core/services/ClaimService";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";

export default defineComponent({
  name: "FormMoveClaim",
  components: {FormErrorMessage, BaseForm, BaseSelect},
  props: {
    claim: {type: Object}
  },
  emits: ['movedClaim'],
  setup(props, {emit}) {
    const refClaim = ref(props.claim)
    const moveCaseModel = ref<any>({policyId: '', insuredId: '', cazeId: ''});
    const policyData = ref<any>({data: [], total: 0, pages: 0})
    const policyField: ISearchField[] = [
      {column: 'clientId', type: 'HIDDEN', operator: OPERATOR.EQ, value: refClaim.value?.client?.id, name: ''},
      {column: 'policyNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Policy#'},
      {column: 'certificate', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Certificate'},
    ]
    const terms = ref({number: '', certificate: ''});
    const searching = ref(false);
    const submitting = ref(false);
    const filter = ref<ISearchFilter>({
      paginationPage: 1,
      paginationSize: 20,
      query: policyField,
      entity: SEARCH_ENTITY.POLICY_VIEW,
      fields: [],
      expands: [],
    })
    const search = () => {
      searching.value = true
      PolicyService.search(filter.value).then(res => {
        policyData.value = res;
      }).finally(() => {
        searching.value = false;
      })
    }

    const move = () => {
      submitting.value = true;
      ClaimService.moveClaim(refClaim?.value?.id, moveCaseModel.value).then(res => {
        emit('movedClaim', res);
      }).finally(() => {
        submitting.value = false
      })
    }
    return {
      policyData,
      terms,
      filter,
      searching,
      moveCaseModel,
      search,
      ...InsuredSelect(''),
      ...MoveClaimCaseSelect(),
      submitting,
      move,
    }
  },
  methods: {
    onLoadCases() {
      console.log(this.moveCaseModel);
      this.loadMoveClaimCase(this.moveCaseModel.policyId, this.moveCaseModel.insuredId)
    }
  }
})
